import { AUTHS } from 'components/Routing/names';
import React from 'react'

const CreateUser            = React.lazy( () => import('components/Views_admin/User/CreateUser'));
const GetUsers              = React.lazy( () => import('components/Views_admin/User/GetUsers'));
const UserMain             = React.lazy( () => import('components/Views_admin/User/UserMain')); 
const UserDetail             = React.lazy( () => import('components/Views_admin/User/UserDetail'));  
const Help                  = React.lazy( () => import('components/Views_admin/Help/Help'));
/* const EditDatePay          = React.lazy( () => import('components/Views_admin/User/EditDatePay')); */



export const USER_V = [

  { path: '/admin/create-user',     component: CreateUser, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.UserCreate, roles: ["ROLE_ADMIN"] },
  { path: '/admin/get-users',       component: GetUsers,       isProtected: true, showFoot: false, showNav: true , membership: false, roles: ["ROLE_ADMIN"] },
  { path: '/admin/user-admin',      component: UserMain,       isProtected: true, showFoot: false, showNav: true , membership: false, roles: ["ROLE_ADMIN"] },
  { path: '/admin/user-detail/:id', component: UserDetail,       isProtected: true, showFoot: false, showNav: true , membership: false, roles: ["ROLE_ADMIN"] },   
  { path: '/admin/help',            component: Help,       isProtected: true, showFoot: false, showNav: true , membership: false, roles: ["ROLE_USER"] }   
];

