import React from "react";

const VM_ACCOUNT        = React.lazy(() => import('components/Views_admin/VmAccount/MainVm'))
const VM_ROLE_LIST      = React.lazy(() => import('components/Views_admin/VmAccount/VmRoleList'))
const VM_ROLE_AUTHS     = React.lazy(() => import('components/Views_admin/VmAccount/VmAssignAuths'))
const VM_ACC_USER       = React.lazy(() => import('components/Views_admin/VmAccount/VmAccUser'))

export const VM_ACCOUNT_V = [
  {path: "/admin/vm-account",            component: VM_ACCOUNT,    isProtected: true,  showFoot: false, showNav: true , membership: false, name: ''},
  {path: "/admin/vm-role/list",          component: VM_ROLE_LIST,  isProtected: true,  showFoot: false, showNav: true , membership: false, name: ''},
  {path: "/admin/vm-assign-auth/:id",    component: VM_ROLE_AUTHS, isProtected: true,  showFoot: false, showNav: true , membership: false, name: ''},  
  {path: "/admin/vm-account-link",       component: VM_ACC_USER  , isProtected: true,  showFoot: false, showNav: true , membership: false, name: ''},  
];