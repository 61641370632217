const setAuth = auth => {
    try {
        localStorage.setItem('auth', JSON.stringify(auth))
        return true
    } catch (error) {
        return false
    }
}

const getAuth = () => {
    try {
        let auth = localStorage.getItem('auth')

        let data = {
            token: '',
            name: '',
            email: '',
            membership: '',
            is_vm: false,
            auths: '',
            link: 0,
            referral: false,
            role: '',
            profileId: null
        }
        if (auth) {
            let _json = JSON.parse(auth)

            data = {
                ...data,
                ..._json
            }

            if (data.token && data.name && data.email) {
                return data
            } else {
                return null
            }
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}

const fakeLogin = () => {
    try {
        let auth = {
            token: '827ccb0eea8a706c4c34a16891f84e7b',
            name: 'Kenned',
            email: 'nedmena@gmail.com',
            membership: '09/05/2021' //por el momento no
        }

        setAuth(auth)
        return true
    } catch (error) {
        return false
    }
}

const logout = () => {
    try {
        localStorage.removeItem('auth')
        localStorage.removeItem('visit')
        return true
    } catch (error) {
        return true
    }
}

const getAttribute = attribute => {
    let data = localStorage.getItem('auth')

    if (!data) return null

    data = JSON.parse(data)

    if (Object.keys(data).includes(attribute)) {
        return data[attribute]
    }

    return null
}

const setAttribute = (attribute, value) => {
    try {
        let data = localStorage.getItem('auth')
        if (!data) return null

        data = JSON.parse(data)

        if (Object.keys(data).includes(attribute)) {
            data[attribute] = value
            localStorage.setItem('auth', JSON.stringify(data))
        }
    } catch (e) {
        console.warn(e)
    }
}

export { setAuth, getAuth, fakeLogin, logout, getAttribute, setAttribute }
