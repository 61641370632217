import { AUTHS } from "components/Routing/names";
import React from "react";

const Wallet                   = React.lazy( () => import('components/Views_admin/Wallet/WalletManagement'));
const MainAccount              = React.lazy( () => import('components/Views_admin/Wallet/MainAccount'));
const YourCards                = React.lazy( () => import('components/Views_admin/Wallet/PaymentMethods'));
const Withdrawals              = React.lazy( () => import('components/Views_admin/Wallet/Withdrawals'));
const InternalWallet           = React.lazy( () => import('components/Views_admin/Wallet/InternalWallet')); 

export const WALLET_V = [
  { path: '/admin/wallet',       component: Wallet,      isProtected: true,  showFoot: false, showNav: true , membership: false },
  { path: '/admin/main-account', component: MainAccount, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.WALLET_ACCOUNT},
  { path: '/admin/your-cards',   component: YourCards,   isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.WALLET_PM},
  { path: '/admin/withdrawals',  component: Withdrawals, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.WALLET_WITHDRAWAL},
  { path: '/admin/ventmove-wallet',       component: InternalWallet,      isProtected: true,  showFoot: false, showNav: true , membership: false },
]