import { AUTHS } from 'components/Routing/names'
import React from 'react'

// prettier-ignore
const ContactMain              = React.lazy( () => import('components/Views_admin/Contact/ContactMain'));
const ContactCategory = React.lazy(() => import('components/Views_admin/Contact/ContactCategory'))
const ContactNewClient = React.lazy(() => import('components/Views_admin/Contact/ContactNewClient'))
const ContactClients = React.lazy(() => import('components/Views_admin/Contact/ContactClients'))
const ContactUpdateStatus = React.lazy(() => import('components/Views_admin/Contact/ContactUpdateStatus'))
const ContactUpdateContact = React.lazy(() => import('components/Views_admin/Contact/ContactUpdateContact'))
const ContactListV2 = React.lazy(() => import('components/Views_admin/Contact/ContactListV2'))

// prettier-ignore
export const CONTACT_V = [
  { path: '/admin/contacts',              component: ContactMain,           isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/crm/groups',            component: ContactCategory,       isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.CONTACT_CAT},
  { path: '/admin/crm/new-client',        component: ContactNewClient,      isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.CONTACT_ADD},
  { path: '/admin/crm/your-clients',      component: ContactListV2,        isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.CONTACT_LIST},
  { path: '/admin/crm/update-status/:id', component: ContactUpdateStatus,   isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.CONTACT_STATUS},
  { path: '/admin/crm/update-contact/:id',component: ContactUpdateContact,  isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.CONTACT_EDIT},
  { path: '/admin/crm/contact-list',component: ContactListV2,               isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.CONTACT_LIST},
];
