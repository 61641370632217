import { AUTHS } from "components/Routing/names";
import React from "react";



const Dashboard                 = React.lazy( () => import('components/Views_admin/Dashboard/Dashboard') );
const DashboardAdmin            = React.lazy( () => import('components/Views_admin/Dashboard/DashAdmin') );
const Membership                = React.lazy( () => import('components/Views_admin/Membership/Membership') );
const Account                   = React.lazy( () => import('components/Views_admin/Account/EditData') );

export const DASHBOARD_V = [
  { path: '/admin',             component: Dashboard,   isProtected: true,  showFoot: false, showNav: true , membership: false, isAdmin:false},
  //{ path: '/admin',             component: DashboardAdmin,   isProtected: true,  showFoot: false, showNav: true , membership: false, isAdmin:true},
  { path: '/admin/membership',  component: Membership,  isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.MEMBERSHIP},
  { path: '/admin/account',     component: Account,     isProtected: true,  showFoot: false, showNav: true , membership: false, name: ''},

  {path: '/moderator', component: DashboardAdmin, isProtected: true, showFoot: false, showNav: true , membership: false, roles: ["ROLE_ADMIN"]}
];

/* 
export const DASHBOARD_A = [
  { path: '/admin/admin',             component: DashboardAdmin,   isProtected: true,  showFoot: false, showNav: true , membership: false},
 
]; */
