import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import { Suspense } from "react";
import { Url } from "components/Routing/Url";
import Loading from "components/Utils/Loading/Loading";
import { useOnceEffect } from "hooks/useOnceEffect";
import { getAll } from "components/Providers/Requests";

const Protected = React.lazy(() => import("components/Routing/ProtectViews"));
const Public = React.lazy(() => import("components/Routing/PublicViews"));
const NewsLetter = React.lazy(() =>
  import("components/Views_public/NewsLetter/NewsLetter")
);

function Routes() {
  const [GO, SET_GO] = React.useState(true);

  /* useOnceEffect(() => {

    let has_allowed = localStorage.getItem(btoa("_wall_pass"))

    function checkLocation() {

      getAll("current-location")
      .then(res => {
        if(res.data.allowed) 
          SET_GO(true)

        localStorage.setItem(btoa("_wall_pass"), JSON.stringify(res.data))
      }).catch(e => {
        localStorage.setItem(btoa("_wall_pass"), JSON.stringify({allowed: false}))
        SET_GO(false)
      })

    }

    if(has_allowed == null) {
      checkLocation()
    } else {
      SET_GO(
        JSON.parse(has_allowed)?.allowed ?? false
      )
    }

  }) */

  return (
   
      <Suspense fallback={<Loading />}>
        {GO && (
          <Switch>
            {Url.map((elm, i) => {
              return (
                <Route
                  exact
                  path={elm.path}
                  key={i}
                  render={(props) => {
                    if (elm.isProtected) {
                      return (
                        <Protected
                          {...{ ...elm, ...props }}
                          component={<elm.component {...props} />}
                        />
                      );
                    } else {
                      return (
                        <Public
                          {...{ ...elm, ...props }}
                          component={<elm.component {...props} />}
                        />
                      );
                    }
                  }}
                />
              );
            })}
          </Switch>
        )}

        {GO === null && <Loading />}

        {GO === false && (
          <Public
            component={<NewsLetter />}
            showNav={false}
            translate={false}
          />
        )}
      </Suspense>
   
  );
}

export default Routes;
