import React from 'react'

const EVENT_MAIN = React.lazy( () => import("components/Views_admin/Events/EventMain") )
const EVENT_ADD = React.lazy( () => import("components/Views_admin/Events/EventAdd") )
const EVENT_EDIT = React.lazy( () => import("components/Views_admin/Events/EventEdit") )
const EVENT_LIST = React.lazy( () => import("components/Views_admin/Events/EventList") )

export const EVENTS_V = [
    { path: '/admin/event-main',          component: EVENT_MAIN,     isProtected: true,  showFoot: false, showNav: true , membership: false},
    { path: '/admin/event-add',           component: EVENT_ADD,      isProtected: true,  showFoot: false, showNav: true , membership: true},
    { path: '/admin/event-edit/:id',      component: EVENT_EDIT,     isProtected: true,  showFoot: false, showNav: true , membership: true},
    { path: '/admin/event-list',          component: EVENT_LIST,     isProtected: true,  showFoot: false, showNav: true , membership: true},
];