import { AUTHS } from 'components/Routing/names';
import React from 'react'

const SiteMain                 = React.lazy( () => import('components/Views_admin/Profile/Main'));
const FormProfile              = React.lazy( () => import('components/Views_admin/Profile/FormProfile'));
const SiteSettings             = React.lazy( () => import('components/Views_admin/Profile/SiteSettings'));
const Templates                = React.lazy( () => import('components/Views_admin/Profile/Templates'));
const TemplateAdd              = React.lazy( () => import('components/Views_admin/Profile/TemplateAdd'));

export const PROFILE_V = [
  { path: '/admin/site-main',           component: SiteMain,     isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/form-profile',        component: FormProfile,  isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.PROFILE_PAGE},
  { path: '/admin/site-setting',        component: SiteSettings, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.PROFILE_SETTINGS},
  { path: '/admin/templates/list',      component: Templates,    isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.PROFILE_TEMPLATES},
  { path: '/admin/templates/add',       component: TemplateAdd,  isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.PROFILE_TEMPLATES},
  { path: '/admin/templates/edit/:id',  component: SiteSettings, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.PROFILE_TEMPLATES},
];