import { AUTHS } from 'components/Routing/names';
import React from 'react'

const ApptManagement           = React.lazy( () => import('components/Views_admin/Appointments/ApptManagement') );
const Appts                    = React.lazy( () => import('components/Views_admin/Appointments/Appts') );
const MyAppts                  = React.lazy( () => import('components/Views_admin/Appointments/MyAppts') );
const ApptCustomer             = React.lazy( () => import('components/Views_admin/Appointments/ApptCustomer') );
const ApptOwner                = React.lazy( () => import('components/Views_admin/Appointments/ApptOwner') );
const ServiceAdd               = React.lazy( () => import('components/Views_admin/Appointments/ServiceAdd') );
const ServiceEdit              = React.lazy( () => import('components/Views_admin/Appointments/ServiceEdit') );
const ServiceList              = React.lazy( () => import('components/Views_admin/Appointments/ServiceList') );


export const SERVICE_V = [
  { path: '/admin/service-management',          component: ApptManagement,  isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/appts',                       component: Appts,           isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.SERVICE_SEE_RESERVATIONS},
  { path: '/admin/appts-made',                  component: MyAppts,         isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.SERVICE_SEE_MY_RESERVATIONS},
  { path: '/admin/service-add',                 component: ServiceAdd,      isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.SERVICE_ADD},
  { path: '/admin/service-list',                component: ServiceList,     isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.SERVICE_LIST},
  { path: '/admin/service-edit/:id',            component: ServiceEdit,     isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.SERVICE_EDIT},
  { path: '/admin/appt-customer/:idAppointment/:apptId',component: ApptCustomer,    isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.SERVICE_SEE_MY_RESERVATIONS},
  { path: '/admin/appt/:idAppointment',         component: ApptOwner,       isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.SERVICE_SEE_RESERVATIONS},
];