import { Loader } from 'rsuite';
import './loading.scss';

export default function Loading()
{
  return (
    <section id="Loading">
      <Loader size="lg" className="loader-bg" />
    </section>
  );
}