import axios from "axios";
import { getAuth, setAuth } from "./Auth";

const HOST = process.env.REACT_APP_API;
const BASE_URL = HOST + "api/"; 

const UN_ERROR = {
  status: 500,
  data: { data: [] },
};

const LOG_ERROR = false;

const logError = (e) => {
  if (LOG_ERROR) console.warn(e);
};

/**
 *
 * @param {*} endpoint
 */
const getAll = async (endpoint = "", header = {}, params = {}) => {
  try {
    var response = null;
    var canContinue = true;
    var contador = 0;

    do {
      response = await axios.get(BASE_URL + endpoint, {
        headers: header,
        params: params,
      });

      if (response.data.error && response.data.data.message === "EXP TOKEN") {
        var valores = changeAuthHeader(response.data.data.token);
        canContinue = valores[0];
        header.Authorization = valores[1];
        contador += 1;
        response = null;
      } else if (
        response.data.error &&
        response.data.data.message === "No token provided"
      ) {
        console.warn("Error, validating credentials");
        throw UN_ERROR;
      } else if (
        response.data.error &&
        response.data.data.message === "NO AUTHORIZATION"
      ) {
        console.warn("NO AUTHORIZATION");
        throw UN_ERROR;
      } else {
        canContinue = true;
      }
    } while (!canContinue && contador < 3);

    return response;
  } catch (error) {
    logError(error);
    if (!error.response) throw UN_ERROR;
    throw error.response;
  }
};

/**
 *
 * @param {*} endpoint
 * @param {*} id
 */
const getOne = async (endpoint = "", id = 0, header = {}, params = {}) => {
  try {
    var response = null;
    var canContinue = true;
    var contador = 0;

    do {
      response = await axios.get(BASE_URL + endpoint + "/" + id, {
        headers: header,
        params: params,
      });

      if (response.data.error && response.data.data.message === "EXP TOKEN") {
        var valores = changeAuthHeader(response.data.data.token);
        canContinue = valores[0];
        header.Authorization = valores[1];
        contador += 1;
        response = null;
      } else if (
        response.data.error &&
        response.data.data.message === "No token provided"
      ) {
        console.warn("Error, validating credentials");
        throw UN_ERROR;
      } else {
        canContinue = true;
      }
    } while (!canContinue && contador < 3);

    return response;
  } catch (error) {
    logError(error);
    if (!error.response) throw UN_ERROR;
    throw error.response;
  }
};

/**
 *
 * @param {*} endpoint
 * @param {*} data
 * @param {*} method
 */
const makeRequest = async (
  endpoint = "",
  data = {},
  method = "post",
  header = {},
  params = {}
) => {
  try {
    var response = null;
    var canContinue = true;
    var contador = 0;

    do {
      response = await axios({
        url: BASE_URL + endpoint,
        method: method,
        data: data,
        headers: header,
        params: params,
      });

      if (response.data.error && response.data.data.message === "EXP TOKEN") {
        var valores = changeAuthHeader(response.data.data.token);
        canContinue = valores[0];
        header.Authorization = valores[1];
        contador += 1;
        response = null;
      } else if (
        response.data.error &&
        response.data.data.message === "No token provided"
      ) {
        console.warn("Error, validating credentials");
        throw UN_ERROR;
      } else {
        canContinue = true;
      }
    } while (!canContinue && contador < 3);

    return response;
  } catch (error) {
    logError(error);
    if (!error.response) throw UN_ERROR;
    throw error.response;
  }
};

const changeAuthHeader = (token) => {
  var auth = getAuth();
  auth.token = token;
  setAuth(auth);

  return [false, auth.token];
};

const STATUS = {
  CODE_BAD_REQUEST: 400,
  CODE_UNAUTHORIZED: 401,
  CODE_FORBIDDEN: 403,
  CODE_NOT_FOUND: 404,
  CODE_METHOD_NOT_ALLOWED: 405,
  CODE_INTERNAL_SERVER_ERROR: 500,
  CODE_BAD_GATEWAY: 502,
};

export { getAll, getOne, makeRequest, STATUS, HOST };
