import { AUTHS } from "components/Routing/names";
import React from "react";

const MainStore              = React.lazy( () => import('components/Views_admin/Store/Main')); 
const StoreAdd               = React.lazy( () => import('components/Views_admin/Store/NewProduct')); 
const StoreProducts          = React.lazy( () => import('components/Views_admin/Store/YourProducts')); 
const StoreOrdersReceived    = React.lazy( () => import('components/Views_admin/Store/OrdersReceived')); 
const StoreOrdersMade        = React.lazy( () => import('components/Views_admin/Store/OrdersMade')); 
const StoreEdit              = React.lazy( () => import('components/Views_admin/Store/EditProduct'));
const StoreDetailOwner       = React.lazy( () => import('components/Views_admin/Store/OrderDetailOwner'));
const StoreDetailMine        = React.lazy( () => import('components/Views_admin/Store/OrderDetailMine'));

export const STORE_V = [
  { path: '/admin/store/main',            component: MainStore,           isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/store/add-product',     component: StoreAdd,            isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.STORE_ADD},
  { path: '/admin/store/your-products',   component: StoreProducts,       isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.STORE_LIST},
  { path: '/admin/store/edit/:id',        component: StoreEdit,           isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.STORE_EDIT},
  { path: '/admin/store/orders-received', component: StoreOrdersReceived, isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.STORE_SEE_REQUESTED},
  { path: '/admin/store/orders-made',     component: StoreOrdersMade,     isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.STORE_SEE_MY_REQUESTED},
  { path: '/admin/store/d-owner/:id',     component: StoreDetailOwner,    isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.STORE_SEE_REQUESTED},
  { path: '/admin/store/d-mine/:id',      component: StoreDetailMine,     isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.STORE_SEE_MY_REQUESTED},
];