import { AUTHS } from 'components/Routing/names';
import React from 'react'

const BlogCreatePost           = React.lazy( () => import('components/Views_admin/Blog/BlogCreatePost'));
const BlogMain                 = React.lazy( () => import('components/Views_admin/Blog/BlogMain'));
const BlogReports              = React.lazy( () => import('components/Views_admin/Blog/BlogReports'));
const BlogViewUpdate           = React.lazy( () => import('components/Views_admin/Blog/BlogViewUpdate'));
const BlogEdit                 = React.lazy( () => import('components/Views_admin/Blog/BlogEdit'));
const BlogPremuin              = React.lazy( () => import('components/Views_admin/Blog/BlogPremiun'));


export const BLOG_V = [
  { path: '/admin/blog-create-post',   component: BlogCreatePost, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.BLOG_ADD},
  { path: '/admin/blog-general',       component: BlogMain,       isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/blog-reports/:id',   component: BlogReports,    isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/blog-view-update',   component: BlogViewUpdate, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.BLOG_LIST},
  { path: '/admin/post-edit/:id',      component: BlogEdit,       isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.BLOG_EDIT},
  { path: '/admin/blog-subscription',  component: BlogPremuin,    isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.BLOG_PREMIUM},
];