import { DASHBOARD_V } from './dashboard/dashboard';
import { SERVICE_V } from './service/service'
import { VENUE_V } from './venue/venue';
import { WALLET_V } from './wallet/wallet';
import { PROFILE_V } from './profile/profile';
import { BLOG_V } from './blog/blog';
import { DONATION_V } from './donations/donations';
import { CHATROOM_V } from './chatroom/chatroom';
import { STORE_V } from './store/store';
import { CONTACT_V } from './contact/contact';
import { VM_ACCOUNT_V } from './vm_account/vm_account';
import { REFERRAL } from './referral/referral';
import { EVENTS_V } from './events/events';
import { USER_V } from './users/user';

export const PRIVATE_V = [

  ...DASHBOARD_V,
  ...VENUE_V,
  ...WALLET_V,
  ...SERVICE_V,
  ...PROFILE_V,
  ...BLOG_V,
  ...DONATION_V,
  ...CHATROOM_V,
  ...STORE_V,
  ...CONTACT_V,
  ...VM_ACCOUNT_V,
  ...REFERRAL,
  ...EVENTS_V,
  ...USER_V
];