export const AUTHS = {
    PROFILE_PAGE: 'profilePage',
    PROFILE_SETTINGS: 'profileSettings',
    PROFILE_TEMPLATES: 'profileTemplates',

    BLOG_ADD: 'blogAdd',
    BLOG_EDIT: 'blogEdit',
    BLOG_DELETE: 'blogDelete',
    BLOG_PREMIUM: 'blogPremium',
    BLOG_LIST: 'blogList',

    //UserCreate:"create-user",
    //GetUsers:"getUsers",
    //EditDatePay:"edit-pay-date",

    CONTACT_CAT: 'contactCategory',
    CONTACT_ADD: 'contactAdd',
    CONTACT_EDIT: 'contactEdit',
    CONTACT_LIST: 'contactList',
    CONTACT_DELETE: 'contactDelete',
    CONTACT_STATUS: 'contactStatus',

    VENUE_ADD: 'venueAdd',
    VENUE_LIST: 'venueList',
    VENUE_EDIT: 'venueEdit',
    VENUE_DELETE: 'venueDelete',
    VENUE_SEE_RESERVATIONS: 'seeVenueReservations',
    VENUE_SEE_MY_RESERVATIONS: 'seeMyVenueReservations',
    VENUE_MAKE_RESERVATIONS: 'makeVenueReservations',

    SERVICE_ADD: 'serviceAdd',
    SERVICE_LIST: 'serviceList',
    SERVICE_EDIT: 'serviceEdit',
    SERVICE_DELETE: 'serviceDelete',
    SERVICE_SEE_RESERVATIONS: 'seeServiceReservation',
    SERVICE_SEE_MY_RESERVATIONS: 'seeMyServiceReservation',
    SERVICE_MAKE_RESERVATIONS: 'makeServiceReservation',

    DONATION_ADD: 'donationAdd',
    DONATION_LIST: 'donationList',
    DONATION_EDIT: 'donationEdit',
    DONATION_DELETE: 'donationDelete',

    STORE_ADD: 'storeAdd',
    STORE_LIST: 'storeList',
    STORE_DELETE: 'storeDelete',
    STORE_EDIT: 'storeEdit',
    STORE_SEE_REQUESTED: 'storeSeeRequested',
    STORE_SEE_MY_REQUESTED: 'storeSeeMyRequested',
    STORE_MAKE_REQUEST: 'storeMakeRequest',

    WALLET_ACCOUNT: 'walletMainAccount',
    WALLET_PM: 'walletPm',
    WALLET_WITHDRAWAL: 'walletWithdrawal',
    MEMBERSHIP: 'membership'
}
