import React from 'react'


const NewsLetter = React.lazy(() => import('components/Views_public/NewsLetter/NewsLetter'));
const Home = React.lazy(() => import('components/Views_public/Home/Home'));
const Contact = React.lazy(() => import('components/Views_public/Contact/Contact'));
const About = React.lazy(() => import('components/Views_public/About/About'));
const Login = React.lazy(() => import('components/Views_public/Login/Login'));
const SignUp = React.lazy(() => import('components/Views_public/SignUp/SignUp'));
const VenuePage = React.lazy(() => import('components/Views_public/Venue/Venue'));
const Site = React.lazy(() => import('components/Views_public/Site/Site'));
const Funding = React.lazy(() => import('components/Views_public/Funding/funding'));
const BlogArticle = React.lazy(() => import('components/Views_public/Post/BlogArticle'));
const BlogByCategories = React.lazy(() => import('components/Views_public/Post/BlogCategories'));
const SearchVenue = React.lazy(() => import('components/Views_public/Filters/SearchVenue'));
const SearchService = React.lazy(() => import('components/Views_public/Filters/SearchService'));
const ProductService = React.lazy(() => import('components/Views_public/Filters/SearchProduct'));
const BlogService = React.lazy(() => import('components/Views_public/Filters/SearchBlog'));
const FundingService = React.lazy(() => import('components/Views_public/Filters/SearchFunding'));
const SearchProfile = React.lazy(() => import('components/Views_public/Filters/SerachProfile'));
const Store = React.lazy(() => import('components/Views_public/Store/Store'));
const ApptView = React.lazy(() => import('components/Views_public/Appointment/ApptView'));
const Event = React.lazy(() => import('components/Views_public/Events/Event'));
const ProfileProducts = React.lazy(() => import('components/Views_public/Store/Products'));
const Posts = React.lazy(() => import('components/Views_public/Post/Posts'));
const Campaings = React.lazy(() => import('components/Views_public/Funding/Campaings'));
const Venues = React.lazy(() => import('components/Views_public/Venue/Venues'));
const Services = React.lazy(() => import('components/Views_public/Appointment/Services'));
const VenuePageP = React.lazy(() => import('components/Views_public/Venue/VenueP'));
const ApptPageP = React.lazy(() => import('components/Views_public/Appointment/ApptViewP'));
const Events = React.lazy(() => import('components/Views_public/Events/Events'));
const Register = React.lazy(() => import('components/Views_public/SignUp/Register'));


export const PUBLIC_V = [

  { path: '/', component: Home, isProtected: false, showFoot: true, showNav: true, tidio: true },
  { path: '/about', component: About, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/contact', component: Contact, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/newsletter', component: NewsLetter, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/venues', component: SearchVenue, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/services', component: SearchService, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/products', component: ProductService, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/funding', component: FundingService, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/posts', component: BlogService, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/profiles', component: SearchProfile, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/login', component: Login, isProtected: false, showFoot: false, showNav: true, tidio: false },
  { path: '/:user/venue-page/:id', component: VenuePage, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/:user/service/:id', component: ApptView, isProtected: false, showFoot: false, showNav: false, tidio: false },
  { path: '/:id/profile/', component: Site, isProtected: false, showFoot: false, showNav: false, translate: false, tidio: false },
  { path: '/:user/campaign/:id/', component: Funding, isProtected: false, showFoot: false, showNav: false, tidio: false, translate: false },
  { path: '/:user/post/:id/:name?', component: BlogArticle, isProtected: false, showFoot: false, showNav: false, tidio: false },
  { path: '/blog-categories/:id', component: BlogByCategories, isProtected: false, showFoot: false, showNav: true, tidio: false },
  { path: '/:user/product/:id', component: Store, isProtected: false, showFoot: false, showNav: false, tidio: false, translate: false },
  { path: '/:user/event/:id', component: Event, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/:user/products', component: ProfileProducts, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/:user/blog/', component: Posts, isProtected: false, showFoot: false, showNav: false, tidio: false, translate: false },
  { path: '/:user/campaings/', component: Campaings, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/:user/venues/', component: Venues, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/:user/services/', component: Services, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/:user/venue/:id', component: VenuePageP, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/:user/services/:id', component: ApptPageP, isProtected: false, showFoot: false, showNav: false, tidio: false, translate: false },
  { path: '/:user/events', component: Events, isProtected: false, showFoot: true, showNav: true, tidio: false, translate: false },
  { path: '/sign-up/:type?/:membership?', component: SignUp, isProtected: false, showFoot: true, showNav: true, tidio: false },
  { path: '/register/:name?', component: Register, isProtected: false, showFoot: true, showNav: true, tidio: false },

];
