import { AUTHS } from 'components/Routing/names';
import React from 'react'

const CampaignList             = React.lazy( () => import('components/Views_admin/Donation/CampaignList'));
const CreateCampaign           = React.lazy( () => import('components/Views_admin/Donation/CreateCampaign'));
const DonationMain             = React.lazy( () => import('components/Views_admin/Donation/DonationMain'));
const CampaignEdit             = React.lazy( () => import('components/Views_admin/Donation/CampaignEdit'));
const DonationsReceived        = React.lazy( () => import('components/Views_admin/Donation/DonationsReceived'));


export const DONATION_V = [
  { path: '/admin/your-campaingn',       component: CampaignList,   isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.DONATION_LIST},
  { path: '/admin/create-campaingn',     component: CreateCampaign, isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.DONATION_ADD},
  { path: '/admin/funding-main',         component: DonationMain,   isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/campaign/edit/:id',    component: CampaignEdit,   isProtected: true,  showFoot: false, showNav: true , membership: false, name: AUTHS.DONATION_EDIT},
  { path: '/admin/campaign/donations-received/:id',  component: DonationsReceived,   isProtected: true,  showFoot: false, showNav: true , membership: false},
];