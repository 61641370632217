import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "rsuite/dist/styles/rsuite-default.min.css";
import "./assets/template.scss";
import "./FontAwesome";

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById("root")
);
