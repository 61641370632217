import { AUTHS } from "components/Routing/names";
import React from "react";

const VenueManagement          = React.lazy( () => import('components/Views_admin/Venues/VenueManagement') );
const VenueAdd                 = React.lazy( () => import('components/Views_admin/Venues/VenueAdd') );
const VenueList                = React.lazy( () => import('components/Views_admin/Venues/VenueList') );
const VenueEdit                = React.lazy( () => import('components/Views_admin/Venues/VenueEdit') );
const VenueReservationsList    = React.lazy( () => import('components/Views_admin/Venues/VenueReservationsList') );
const VenueReservationOwner    = React.lazy( () => import('components/Views_admin/Venues/VenueReservationOwner') );
const VenueReservationCustomer = React.lazy( () => import('components/Views_admin/Venues/VenueReservationCustomer') );
const MyReservations           = React.lazy( () => import('components/Views_admin/Venues/MyReservations') );
const VenueResports            = React.lazy( () => import('components/Views_admin/Venues/Reports') );

export const VENUE_V = [
  { path: '/admin/venue-management',                           component: VenueManagement,          isProtected: true,  showFoot: false, showNav: true , membership: false},
  { path: '/admin/venue-add',                                  component: VenueAdd,                 isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_ADD},
  { path: '/admin/venue-list',                                 component: VenueList,                isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_LIST},
  { path: '/admin/venue-edit/:id',                             component: VenueEdit,                isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_EDIT},
  { path: '/admin/venue-reservations-list',                    component: VenueReservationsList,    isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_SEE_RESERVATIONS},
  { path: '/admin/my-reservations',                            component: MyReservations,           isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_SEE_MY_RESERVATIONS},
  { path: '/admin/venue-reservation/:idReservation',           component: VenueReservationOwner,    isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_SEE_RESERVATIONS},
  { path: '/admin/venue-reservation-customer/:idReservation/:idVenue',  component: VenueReservationCustomer, isProtected: true,  showFoot: false, showNav: true , membership: true, name: AUTHS.VENUE_SEE_MY_RESERVATIONS},
  { path: '/admin/venues/reports',                              component: VenueResports,            isProtected: true,  showFoot: false, showNav: true , membership: true},
]