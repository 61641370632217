import { useEffect } from 'react';

export function useOnceEffect(callback) {

    useEffect(() => {

        const cleanup = callback()

        if(cleanup)
            return cleanup

    // eslint-disable-next-line
    }, [])
}