import { library } from '@fortawesome/fontawesome-svg-core'
import {
    fab,
    faInstagram,
    faTwitter,
    faFacebook,
    faSpotify,
    faSoundcloud,
    faMixcloud,
    faTwitch,
    faDiscord
} from '@fortawesome/free-brands-svg-icons'

import {
    faHome,
    faTicketAlt,
    faDollarSign,
    faPlayCircle,
    faPlay,
    faUser,
    faEllipsisV,
    faBars,
    faTimes,
    faEllipsisH,
    faWallet,
    faPen,
    faGift,
    faLaptopHouse,
    faHandHoldingUsd,
    faIdCard,
    faUserCircle,
    faShare,
    faAddressBook,
    faSignOutAlt,
    faChevronDown,
    faSpinner,
    faClipboard,
    faHotel,
    faBookOpen,
    faEye,
    faTrash,
    faUsers,
    faMarker,
    faInfoCircle,
    faMapPin,
    faMapMarker,
    faCheck,
    faBorderAll,
    faMapMarkerAlt,
    faCog,
    faCertificate,
    faPalette,
    faPlus,
    faHandshake,
    faChartPie,
    faStore,
    faBriefcase,
    faSave,
    faMoneyBillWave,
    faFileExport,
    faUsersCog,
    faShareAlt,
    faSort,
    faSortDown,
    faSortUp,
    faLink,
    faUnlink,
    faRedoAlt,
    faFileImport,
    faPhoneAlt,
    faPhone,
    faEnvelope,
    faClock
} from '@fortawesome/free-solid-svg-icons'

import { faCalendar, faComments } from '@fortawesome/free-regular-svg-icons'

library.add(
    fab,
    faAddressBook,
    faBars,
    faBookOpen,
    faBorderAll,
    faBriefcase,
    faCalendar,
    faCertificate,
    faClipboard,
    faChartPie,
    faCheck,
    faCog,
    faComments,
    faDiscord,
    faDollarSign,
    faEllipsisH,
    faEye,
    faEllipsisV,
    faFacebook,
    faFileExport,
    faFileImport,
    faGift,
    faHandHoldingUsd,
    faHandshake,
    faHome,
    faHotel,
    faIdCard,
    faInstagram,
    faLaptopHouse,
    faLink,
    faMarker,
    faMapMarkerAlt,
    faMixcloud,
    faMoneyBillWave,
    faPalette,
    faPen,
    faPlay,
    faPlayCircle,
    faPlus,
    faShare,
    faShareAlt,
    faSave,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSoundcloud,
    faSpotify,
    faStore,
    faTimes,
    faTrash,
    faTwitter,
    faTwitch,
    faUnlink,
    faUser,
    faUserCircle,
    faUsersCog,
    faWallet,
    faTicketAlt,
    faChevronDown,
    faRedoAlt,
    faSpinner,
    faUsers,
    faInfoCircle,
    faMapPin,
    faMapMarker,
    faPhoneAlt,
    faPhone,
    faEnvelope,
    faClock
)
